<template>
    <div class="property-details-modal">

      <div v-for="(field, index) in Object.keys(titoli)" v-bind:key="'property-details-modal-field-' + index">

        <div v-if="details[field]">
          <h6 class="property-detail-title">
            {{ titoli[field] }}
          </h6>
          <div class="property-detail-content">
            <p v-html="details[field]"></p>
          </div>
          <br />
        </div>

      </div>

      <div v-if="details.parkingInfos">
        <h6 class="property-detail-title">Parcheggio</h6>
        <div class="property-detail-content" v-html="details.parkingInfos" />
        <br />
      </div>

      <div v-if="details.attributes.pets">
        <h6 class="property-detail-title">Policy animali</h6>
        <ul v-for="(value,index) in details.attributes.pets" v-bind:key="'property-info-'+index">
          <li v-html="value.name" class="property-detail-content"></li>
        </ul>
        <br />
      </div>

    </div>
</template>

<script>
export default {
    name: 'property-toKnow',
    props:
    {
        details: Object,
    },
    data(){
      return {
        titoli: {
          //specialDetails: "INFORMAZIONI IMPORTANTI", // sono già indicate fuori da questa modal (ed è un'array)

          fees_mandatory: "Costi obbligatori",
          fees_optional: "Costi opzionali",
          renovations: "Lavori di ristrutturazione",
          specialCheckInInstructions: "Istruzioni speciali per il check in",
          checkInInstructions: "Istruzioni per il check in",
          knowBeforeYouGon: "Da sapere prima di partire",

          //amenities: "Servizi",
          //pets: "Policy animali",
          //nations_ratings: "Rating",
          //business_amenities: "Servizi business",
          //rooms: "Descrizione delle camere",
          //attractions: "Attrazioni nelle vicinanze",
          //location: "Location",

        },
      }
    },
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import './src/scss/_custom.scss';

    // see app.scss
</style>